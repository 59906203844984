const terms_en_us = () => {
  return (
    <div key="terms-en-us">
      <h2>"Notice for connecting Crypto wallet"</h2>
      <p>
        Please make sure that you have read carefully, fully understood and accepted
        all contents of this notice before confirming this notice. The following
        content will take effect after your confirmation and will be legally binding
        on both parties.
      </p>
      <div><h3>1. About this service</h3></div>
      (1) This service will help you connect your crypto wallet and allow Heymandi
      access to all or part of the NFT in your crypto wallet. Regarding which Crypto
      wallet and NFT series are permitted, please refer to the page display from time
      to time. (2) You understand and agree that the relevant information such as the
      crypto wallet page and the graphic presentation of NFT are provided by the
      crypto wallet service provider or NFT project owner. Heymandi act solely as a
      platform display technology services.
      <div><h3>2. Scope of application This service currently does</h3></div>
      not support users from nations where applicable laws does not enable their
      residents to access this service, such as mainland China residents.
      <div><h3>3. Connection Rules</h3></div>
      (1) Please connect your crypto wallet according to the process and rules
      indicated on the page. Heymandi will obtain and display your crypto wallet
      information and NFT information in the crypto wallet. You understand and
      acknowledge that Heymandi will safeguard your privacy in accordance with the
      Heymandi Privacy Policy. (2) This server only support connecting one crypto
      wallet. If you want to change the connected wallet, please remove connected the
      crypto wallet first. (3) You understand and know that Heymandi has the right to
      change the contents of this notice or adjust this service after making
      reasonable judgments for business upgrades, business strategy adjustments, or to
      cooperate with changes in national regulations, policies, and regulatory
      requirements in Heymandi's operations. Before the change, Heymandi will announce
      it in advance by means of announcements or system notifications, etc. Your
      continued use of this service seven days after the change is announced will
      constitute your acceptance of the changed content.
      <div><h3>4. promises and warranties</h3></div>
      (1) Please ensure that you have the full ability and qualification to agree to
      and use this service in accordance with any applicable laws, and can
      independently assume legal responsibility for all your use of this service in
      accordance with the law. (2) You understand and agree that if you violate any
      applicable laws, these notices, the terms of service of this platform, and the
      relevant agreement specifications of the platform's cooperating partners
      (including but not limited to crypto wallet platforms, NFT trading platforms,
      etc.), or Heymandi reserves the right to unilaterally terminate the provision of
      this service to you in whole or in part by using the service in any manner that
      is inappropriate or contrary to good faith.
      <div><h3>5. Disclaimer</h3></div>
      (1) Heymandi only provides platform display technology services, Heymandi does
      not actually provide crypto wallet, NFT blockchain or NFT transaction services.
      If you have questions about the above services, please contact the specific
      service provider that provides the relevant service. (2) We will do our best to
      provide you with active and effective support related to this service, but due
      to force majeure (including any applicable laws or regulatory authorities'
      restrictive requirements, sanctions or suspension of the Customer service
      despite our reasonable efforts), or hacker attack, system instability, network
      interruption, etc., may cause the service to be interrupted or fail to meet your
      requirements. We cannot guarantee that this service will meet your
      requirements.
    </div>
  )
}
export default terms_en_us;
