import React, { Component } from "react";
import { withTranslation, Trans } from "react-i18next"
import "../css/cookies.css";

class CookiesPolicy extends Component {

    onClickClose = () => {
        document.getElementById('main-container').classList.remove('d-none')
        document.getElementById('cookies-container').classList.add('d-none')
    }

    render() {
        return (
            <div id="cookies-container" className="d-none">
                <div className="close-icn">
                    <img alt="" src={`${this.props.imagePath}icn_close.webp`} onClick={() => this.onClickClose()}/>
                </div>
                <Trans i18nKey='cookies'/>
            </div>
        );
    }
}

export default withTranslation()(CookiesPolicy);
