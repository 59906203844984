const terms_zh_tw = () => {
    return (
        <div key="terms-zh-tw">
            <h2>密碼錢包連接使用須知</h2>
            <p>請確保您在確認本須知之前已短仔細閱讀、充分理解並毫無保留地接受本須知所有內容。以下內容自您確認後生效並對雙方產生法律拘束力。</p>
            <div><h3>1.關於本服務 </h3></div>
            (1)本服務將幫助您連接您的密碼錢包並 Heymandi
            展示密碼錢包中的全部或部分NFT。目前已開放連接的密碼錢包帳戶以及NFT系列，請以頁面展示為準。
            (2)您理解並同意，密碼錢包頁面和NFT的圖文展示介紹等相關資訊均由密碼錢包服務提供商或NFT發行方提供。
            Heymandi 謹提供平台展示技術服務。 
            <div><h3>2.適用範圍 </h3></div>
            本服務目前不支持根擄任何適用法律不支持提供本服務的用戶，如中國大陸居民。
            <div><h3>3.連接規則  </h3></div>
            (1)請您根據頁面提示的流程、規則連接您的密碼錢包 Heymandi
            將獲得並對外展示您的密碼錢包資訊以及密碼錢包內的NFT資訊。您理解並確認，
            Heymandi 將根據 《Heymandi 隱私政策》保鑊您隱私安全。
            (2)本服移謹支持連接一個密碼錢包，如您希望更換密碼錢包，請先解除已連接的密碼錢包帳號。
            (3)您理解並知曉，為業務升級、經營策略調整或 配合Heymandi
            所營運中的國家法規政策、監管要求等變化，Heymandi
            有權經合理判斷後變更本須知內容或調整本服務，在作出該等變更前， Heymandi
            將提前以公告、或系統消息等方式展，您於變更公示七日後繼續使用本服務視焉同意並系繼續遵守變更後的內容。
            <div><h3>4．承諾與保證</h3></div>
            (1)請確保您根據任何適用法律均具偏完全能力和資格同意、使用本服務，並可依法對您使用本服務的全部行為獨立承擔法律責任。
            (2)您理解並同意，如您存在違反任何適用法律、
            本須知、本平台服務條款以及本平台合作移伴（包括但不限於密碼錢包平台、
            NFT交易平台等）相關協議規範的行為，或通過任何不正當、違背誠實信用的方式使用本服務，Heymandi
            有權單方面終止向您提供全部或部分本服務。 
            <div><h3>5．免責聲明 </h3></div>
            (1) Heymandi
            謹提供平台展示技術服務， Heymandi 不實際提供密碼錢包、NFT區塊鏈 或 NFT
            的實際交易服務。如有關上述服務的問題，請聯絡具體提供相關服務的服務提供者。
            (2)我們將盡最大努力為您提供與本服務有關的積極有效的支持，但因不可抗力（包括任何適用法律或監管部門針封本服務的限制性要求、制裁或其他經我方合理努力無法客服的情況），或黑客攻擎、系統不穩定、
            網路中斷等原因，均可能造成本服務中斷或不能滿足您的要求，我們無法保証本服務效果一定能滿足您的要求。"
        </div>
    );
};
export default terms_zh_tw;
