import React, { Component } from 'react';
import { Navbar, Container, Nav, NavDropdown, Dropdown } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import '../css/header.css'

class Header extends Component {
    
    scrollTo = (id) => {
        document.getElementById(id).scrollIntoView();
    }

    onClickComingSoon = () => {
        document.getElementById('main-container').classList.add('d-none')
        document.getElementById('coming-soon-container').classList.add('fade-in-comp')
        document.getElementById('coming-soon-container').classList.remove('d-none')
    }

    render() {
        let { t } = this.props
        
        return (
            <Navbar bg="#FFFFFF" expand="lg">
                <Container>
                    <Navbar.Brand>
                        <img alt="" src={`${this.props.imagePath}logo.webp`} id="logo"/>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="nav-collapse" id="toggle"/>
                    <Navbar.Collapse id="nav-collapse">
                        <Nav>
                            <Nav.Link onClick={()=>this.scrollTo('landing-container')}>{t('nav-1')}</Nav.Link>
                            <div className="hr"/>
                            {/* <Nav.Link onClick={()=>this.scrollTo('nft-container')}>{t('nav-2')}</Nav.Link>
                            <div className="hr"/> */}
                            <Nav.Link onClick={()=>this.scrollTo('faq-bg')}>{t('nav-3')}</Nav.Link>
                            <div className="hr"/>
                            <Nav.Link onClick={()=>this.onClickComingSoon()}>{t('nav-4')}</Nav.Link>
                            <div className="hr"/>
                            <NavDropdown title={t('lang')} id="navbarScrollingDropdown" drop="down-centered">
                                <div>
                                    <NavDropdown.Item onClick={()=>this.props.i18n.changeLanguage('en-US')}>EN</NavDropdown.Item>
                                    <Dropdown.Divider />
                                    <NavDropdown.Item onClick={()=>this.props.i18n.changeLanguage('zh-TW')}>繁體</NavDropdown.Item>
                                    <Dropdown.Divider />
                                    <NavDropdown.Item onClick={()=>this.props.i18n.changeLanguage('zh-CN')}>簡體</NavDropdown.Item>
                                </div>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        )
    }
}

export default withTranslation()(Header);
