import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "../css/comingSoon.css";

class ComingSoon extends Component {

    onClickClose = () => {
        document.getElementById('main-container').classList.remove('d-none')
        document.getElementById('coming-soon-container').classList.add('d-none')
    }

    render() {
        let { t } = this.props
        
        return (
            <div id="coming-soon-container" className="d-none">
                <div className="close-icn">
                    <img alt="" src={`${this.props.imagePath}icn_close.webp`} onClick={() => this.onClickClose()} />
                </div>
                <div id="cs-title">
                    {t('cs-title')}
                </div>
                <div id="cs-content">
                    {t('cs-content')}
                </div>
            </div>
        );
    }
}

export default withTranslation()(ComingSoon);
