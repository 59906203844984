const terms_zh_cn = () => {
    return (
        <div key="terms-zh-cn">
            <h2>密码钱包连接使用须知</h2>
            <p>请确保您在确认本须知之前已短仔细阅读、充分理解并毫无保留地接受本须知所有内容。以下内容自您确认后生效并对双方产生法律拘束力。</p>
            <div><h3>1.关于本服务 </h3></div>
            (1)本服务将帮助您连接您的密码钱包并 Heymandi
            展示密码钱包中的全部或部分NFT。目前已开放连接的密码钱包帐户以及NFT系列，请以页面展示为准。
            (2)您理解并同意，密码钱包页面和NFT的图文展示介绍等相关资讯均由密码钱包服务提供商或NFT发行方提供。
            Heymandi 谨提供平台展示技术服务。
            <div><h3>2.适用范围</h3></div>
            本服务目前不支持根掳任何适用法律不支持提供本服务的用户，如中国大陆居民。
            <div><h3>3.连接规则 </h3></div>
            (1)请您根据页面提示的流程、规则连接您的密码钱包 Heymandi
            将获得并对外展示您的密码钱包资讯以及密码钱包内的NFT资讯。您理解并确认，
            Heymandi 将根据 《Heymandi 隐私政策》保镬您隐私安全。
            (2)本服移谨支持连接一个密码钱包，如您希望更换密码钱包，请先解除已连接的密码钱包帐号。
            (3)您理解并知晓，为业务升级、经营策略调整或 配合Heymandi
            所营运中的国家法规政策、监管要求等变化，Heymandi
            有权经合理判断后变更本须知内容或调整本服务，在作出该等变更前， Heymandi
            将提前以公告、或系统消息等方式展，您于变更公示七日后继续使用本服务视焉同意并系继续遵守变更后的内容。
            <div><h3>4．承诺与保证 </h3></div>
            (1)请确保您根据任何适用法律均具偏完全能力和资格同意、使用本服务，并可依法對您使用本服务的全部行为独立承担法律责任。
            (2)您理解并同意，如您存在违反任何适用法律、
            本须知、本平台服务条款以及本平台合作移伴（包括但不限于密码钱包平台、
            NFT交易平台等）相关协议规范的行为，或通过任何不正当、违背诚实信用的方式使用本服务，Heymandi
            有权单方面终止向您提供全部或部分本服务。 
            <div><h3>5．免责声明 </h3></div>
            (1) Heymandi
            谨提供平台展示技术服务， Heymandi 不实际提供密码钱包、NFT区块链 或 NFT
            的实际交易服务。如有关上述服务的问题，请联络具体提供相关服务的服务提供者。
            (2)我们将尽最大努力为您提供与本服务有关的积极有效的支持，但因不可抗力（包括任何适用法律或监管部门针封本服务的限制性要求、制裁或其他经我方合理努力无法客服的情况），或黑客攻擎、系统不稳定、
            网路中断等原因，均可能造成本服务中断或不能满足您的要求，我们无法保证本服务效果一定能满足您的要求。"
        </div>
    );
};
export default terms_zh_cn;
