import React, { Component } from "react";
import { withTranslation, Trans } from 'react-i18next';
import "../css/terms.css";

class Terms extends Component {
    onClickClose = () => {
        document.getElementById("main-container").classList.remove("d-none");
        document.getElementById("terms-container").classList.add("d-none");
    };

    render() {
        return (
            <div id="terms-container" className="d-none">
                <div className="close-icn">
                    <img alt="" src={`${this.props.imagePath}icn_close.webp`} onClick={() => this.onClickClose()}/>
                </div>
                <Trans key='terms' i18nKey='terms'/>
            </div>
        );
    }
}

export default withTranslation()(Terms);
