const cookie_zh_tw = () => {
	return (
		<div key="cookies-policy-zh-tw">
			<h1>Cookie 政策</h1>
			<h3>簡介</h3>
			<p><span>Heymandi 致力保護你的隱私。我們的目標是為你提供值得信賴、領先業界的產品和服務，讓你只需專心建立更多的人際關係。我們對隱私的做法是提供你清楚的資訊，讓你瞭解我們處理資料的實務。</span></p>
			<p><span>本 Cookie 政策解釋什麼是 Cookie、當你造訪我們的網站時，哪種 Cookie 會放在你的裝置上，以及我們使用 Cookie 的方式。 </span></p>
			<p><span>本 Cookie 政策不會涉及一般我們處理你個人資訊的方式。要瞭解我們如何處理你的個人資訊，請在</span><a
				href="https://www.heymandi.com/terms/privacy"><span>這裡</span></a>參閱我們的隱私政策<span>。</span></p>
			<h3>什麼是 Cookie？</h3>
			<p><span>Cookie 是向網頁瀏覽器或裝置記憶體發送，或從之存取的小文字檔。&nbsp;Cookie 通常包含產生 Cookie 的網域（網際網路位置）名稱、Cookie
				的「存留期」（即過期的時間），以及隨機產生的特殊數字或類似識別碼。Cookie 還可能包含有關你設備的資訊（例如使用者設定、瀏覽歷程記錄，以及使用我們的服務時採取的行動）。</span></p>
			<h3>有不同類型的 Cookie 嗎？</h3>
			<p><em><span>第一方和第三方廠商 Cookie</span></em></p>
			<p><span>有第一方廠商和第三方廠商 Cookie。第一方廠商 Cookie 會由我們直接放在你的裝置上。例如，我們使用第一方廠商 Cookie 讓我們的網站適應你瀏覽器的語言喜好設定，並更加瞭解你對我們網站的使用。第三方廠商
				Cookie 是由我們的合作夥伴和服務供應商放在你的裝置上。例如，我們使用第三方廠商 Cookie 來測量我們網站上的使用者人數，或者讓你可跨社群媒體平台與他人分享內容。</span></p>
			<p><em><span>工作階段和永續性 Cookie</span></em></p>
			<p><span>還有工作階段和永續性 Cookie。工作階段 Cookie 會在瀏覽器關閉後結束。我們使用工作階段 Cookie
				的原因很多，包括更瞭解你在單一瀏覽器工作階段中如何使用我們的網站，並協助你更有效使用我們的網站。永續性&nbsp;Cookie 的壽命較長，而且在瀏覽器關閉時不會自動移除。這些類型的 Cookie
				旨在協助你快速再次登入我們的網站，以及分析的目的。</span></p>
			<h3>有其他追蹤技術嗎，例如 Web 指標？</h3>
			<p><span>其他技術，例如 Web 指標（也稱為像素標記或清晰 gif）、追蹤用 URL 或軟體開發套件（SDK）等都用於類似目的。Web
				指標是微小圖形檔，包含一個特殊識別碼，讓我們在有人造訪我們的服務或開啟我們傳送的電子郵件時可以辨識。追蹤用 URL 是自訂產生的連結，可協助我們瞭解網頁的流量來源。SDK 是包含在 App 中的小塊代碼，它的功能類似
				Cookie 和 Web 指標。</span></p>
			<p><span>為了簡便起見，本 Cookie 政策將這些技術統稱為「Cookie」。</span></p>
			<h3>我們使用 Cookie 的目的是什麼？ </h3>
			<p><span>與多數線上服務供應商一樣，我們使用 Cookie 來提供、保護和改善我們的服務，包括記住你的喜好、當你造訪我們的網站時識別你，並根據你的興趣量身打造個人化廣告。為了實現這些目的，我們還會將 Cookie
				中的資訊與我們持有關於你的其他個人資訊連結起來。</span></p>
			<p><span>當你造訪我們的網站時，以下幾種或所有類型的 Cookie 可能會放在你的裝置上。</span></p>
			<p><i>必要網站 Cookie</i></p>
			<p>為了透過我們的網站提供你服務並使用部分網站功能（例如造訪安全區域），這些 Cookie 有其絕對必要性。</p>
			<p><i>分析 Cookie</i></p>
			<p>這些 Cookie 協助我們瞭解我們的網站如何受到使用、推廣活動如何有效進行，並協助我們為你量身打造和改善我們的服務。</p>
			<p><i>廣告 Cookie</i></p>
			<p><span>這些 Cookie 用於讓廣告資訊與你更為相關。&nbsp;這些 Cookie
				的功能具有諸如防止同一廣告持續不斷重現、確保廣告商的廣告正確顯示、根據你的興趣選擇廣告，並測量顯示的廣告數量及效能，例如多少人點擊了特定廣告。</span></p>
			<h3>你可如何控制 Cookie？ </h3>
			<p><span>必要網站 Cookie 是用作維持網站的正常運作，因此不能關閉。它們通常是只會根據你於網站上的行動來設置，即是你對執行的服務請求，例如設定語言等。
				你可以從你的瀏覽器設置封鎖有關Cookie，但這會讓網站的某些部份無法正常運作。只有當你允許我們使用廣告Cookie及分析Cookie，我們才會使用它們。請注意，如果你變更 Cookie 喜好設定，可能降低網站瀏覽品質。某些情況下，你甚至會可能發現你無法使用我們網站的全部或部分內容。</span></p>
			<p><em><span>瀏覽器和裝置控制項</span></em></p>
			<p><span>部分網頁瀏覽器讓你可設定控制或拒絕 Cookie，或者當 Cookie 放在你的電腦上時提醒你。&nbsp;每個網際網路瀏覽器的 Cookie
				管理程序稍有不同。你可在瀏覽器説明功能表中瞭解其特定步驟。</span></p>
			<p><span>你還可透過啟動行動裝置上的相應設定，來重新設定裝置識別碼。每個裝置的識別碼管理程序稍有不同。你可在裝置説明或設定功能表中瞭解其特定步驟。</span></p>
			<h3>Google™ Cookie </h3>
			<p><em><span>Google 希望確保你瞭解 Google 資料收集技術</span></em></p>
			<p><em><span>Google 分析</span></em></p>
			<p><span>我們使用 Google 分析，這項 Google 服務使用 Cookie 和其他資料收集技術，收集有關你使用網站和服務的資訊，以利做成網站趨勢報告。</span></p>
			<p><span>你可透過造訪 </span><a
				href="http://www.google.com/settings/ads"><span>www.google.com/settings/ads</span></a><span>，或從 </span><a
					href="https://tools.google.com/dlpage/gaoptout"><span>https://tools.google.com/dlpage/gaoptout</span></a> 下載
				Google 分析的退出瀏覽器附加元件，來退出 Google 分析<span>。</span></p>
			<h3>如何聯繫我們？</h3>
			<p><span>如果你對本 Cookie 政策有任何疑問，請電郵至 <a herf="mailto:support@heymandi.com">support@heymandi.com</a> 聯繫我們。</span></p>
		</div>)
}

export default cookie_zh_tw