import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import "../css/featCard.css";
import { withTranslation, Trans } from "react-i18next";

class FeatCard extends Component {

    render() {
        let { t } = this.props

        return (
            <div className="position-relative">
                <img alt="" src={`${this.props.imagePath}img_circles.webp`} id="feat-card-bg" />
                <Row id="feat-card-container" className="g-0">
                    <Col sm={12} lg={6} xxl={{ offset: 1, span: 5 }}>
                        <div>
                            <div className="feature-card" id="card-1">
                                <img alt="" src={`${this.props.imagePath}icn_match_rate.webp`} className="img" />
                                <div className="title">
                                    {t('feat-card-title-1')}
                                </div>
                                <div className="content" id="content-1">
                                    {<Trans
                                        i18nKey="feat-card-content-1"
                                        components={{ s: <span className="fw-900" /> }}
                                    />}
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col sm={{ offset: 6, span: 6 }} lg={{ offset: 0, span: 6 }} xxl={{ offset: 1, span: 5 }}>
                        <div>
                            <div className="feature-card" id="card-2">
                                <img alt="" src={`${this.props.imagePath}icn_personality.webp`} className="img" />
                                <div className="title">
                                    {t('feat-card-title-2')}
                                </div>
                                <div className="content" id="content-2">
                                    {<Trans
                                        i18nKey="feat-card-content-2"
                                        components={{ s: <span className="fw-900" /> }}
                                    />}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <img alt="" src={`${this.props.imagePath}img_3_cats.webp`} id="feat-card-cats" />
            </div>
        );
    }
}

export default withTranslation()(FeatCard);
