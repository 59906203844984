import React, { Component } from 'react';
import Landing from './pages/Landing';
import 'bootstrap/dist/css/bootstrap.css'
import './css/app.css'
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/i18n'

class App extends Component {
  render () {
    return (
      <I18nextProvider i18n={i18n}>
        <Landing/>
      </I18nextProvider>
    )
  }
}

export default App;
