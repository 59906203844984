import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';


// Import translations
import EN from './en-US.json';
import terms_en_us from './terms-en-US';
import cookies_en_us from './cookies-en-US'

import TW from './zh-TW.json';
import terms_zh_tw from './terms-zh-TW';
import cookies_zh_tw from './cookies-zh-TW'

import CN from './zh-CN.json';
import terms_zh_cn from './terms-zh-CN';
import cookies_zh_cn from './cookies-zh-CN'

const en_US = {
  ...EN,
  terms: terms_en_us(),
  cookies: cookies_en_us()
}

const zh_TW = {
  ...TW,
  terms: terms_zh_tw(),
  cookies: cookies_zh_tw()
}

const zh_CN = {
  ...CN,
  terms: terms_zh_cn(),
  cookies: cookies_zh_cn()
}

// Configure i18next
i18n
  .use(LanguageDetector)
  .use(initReactI18next) // Bindings for React
  .init({
    
    // Specify the supported languages
    // lng: 'en-US',
    fallbackLng: 'en-US',
    supportedLngs: ['en-US', 'zh-CN', 'zh-TW'],
    // Load the translations
    resources: {
      "en-US": { "translation": en_US },
      "zh-CN": { "translation": zh_CN },
      "zh-TW": { "translation": zh_TW },
    },

    react: {
      transKeepBasicHtmlNodesFor: ['ol', 'li']
    },
    
    returnObjects: false,
    returnedObjectHandler: (key, value, options) => value,
  })
  .then(async () => {
    let faqs = await fetch('https://dteklg4bowqr.cloudfront.net/nft/website/faqs.json').then(res => res.json()).catch(console.log())
    for (let lng of i18n.options.supportedLngs) {
      i18n.addResourceBundle(lng, "translation", faqs[lng], true, false)
    }
    // trigger re-render after adding faq to translation resources
    i18n.changeLanguage(i18n.language)
  })
  .catch(e => console.log(e))
  // testing purpose
  // .then(window.i18next = i18n, console.log(i18n.services.resourceStore.data))


export default i18n;
