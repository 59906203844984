const cookie_en_us = () => {
    return (
        <div key="cookies-policy-en-us">
            <h1>Cookie Policy</h1>
            <h3>Introduction</h3>
            <p><span>Heymandi is committed to protecting your privacy. We aim to provide trustworthy, industry-leading
                products
                and services so that you can focus on building meaningful connections. Our approach to privacy
                is to provide
                you with clear information about our data practices. </span></p>
            <p><span>This Cookie Policy explains what cookies are, what types of cookies are placed on your device when you
                visit our website and how we use them.</span></p>
            <p><span>This Cookie Policy does not address how we deal with your personal information generally. To learn more
                about how we process your personal information, please see our Privacy Policy </span><a
                    href="https://www.heymandi.com/terms/privacy"><span>here</span></a><span>.</span></p>
            <h3>What is Cookie？</h3>
            <p><span>Cookies are small text files that are sent to or accessed from your web browser or your device’s
                memory. A
                cookie typically contains the name of the domain (internet location) from which the cookie
                originated, the
                “lifetime” of the cookie (i.e., when it expires) and a randomly generated unique number or
                similar
                identifier. A cookie also may contain information about your device, such as user settings,
                browsing history
                and activities conducted while using our services.</span></p>
            <h3>Any different types of cookies?</h3>
            <p><em><span>First-party and third-party cookies</span></em></p>
            <p><span>There are first-party cookies and third-party cookies. First-party cookies are placed on your device
                directly by us. For example, we use first-party cookies to adapt our website to your browser’s
                language
                preferences and to better understand your use of our website. Third-party cookies are placed on
                your device
                by our partners and service providers. For example, we use third-party cookies to measure user
                numbers on
                our website or to enable you to share content with others across social media platforms.</span>
            </p>
            <p><em><span>Session and persistent cookies</span></em></p>
            <p><span>There are session cookies and persistent cookies. Session cookies only last until you close your
                browser.
                We use session cookies for a variety of reasons, including to learn more about your use of our
                website
                during one single browser session and to help you to use our website more efficiently.
                Persistent cookies
                have a longer lifespan and aren't automatically deleted when you close your browser. These types
                of cookies
                are primarily used to help you quickly sign-in to our website again and for analytical
                purposes.</span></p>
            <h3>Any other tracking technologies, like web beacons?</h3>
            <p><span>Other technologies such as web beacons (also calls pixel tags or clear gifs), tracking URLs or software
                development kits (SDKs) are used for similar purposes. Web beacons are tiny graphics files that
                contain a
                unique identifier that enable us to recognise when someone has visited our service or opened an
                e-mail that
                we have sent them. Tracking URLs are custom generated links that help us understand where the
                traffic to our
                webpages comes from. SDKs are small pieces of code included in apps, which function like cookies
                and web
                beacons.</span></p>
            <p><span>For simplicity, we also refer to these technologies as “cookies” or "cookie" in this Cookie
                Policy.</span>
            </p>
            <h3>What do we use cookies for?</h3>
            <p><span>Like most providers of online services, we use cookies to provide, secure and improve our services,
                including by remembering your preferences, recognizing you when you visit our website and
                personalizing and
                tailoring ads to your interests. To accomplish these purposes, we also may link information from
                cookies
                with other personal information we hold about you.</span></p>
            <p><span>When you visit our website, some or all of the following types of cookies may be set on your
                device.</span>
            </p>
            <p><i>Essential Website Cookies</i></p>
            <p>These cookies are strictly necessary to provide you with services available through our website and to use
                some
                of its features, such as access to secure areas.</p>
            <p><i>Analytics Cookies</i></p>
            <p>These cookies help us understand how our website is being used, how effective marketing campaigns are, and
                help
                us customize and improve our websites for you.</p>
            <p><i>Advertising Cookies</i></p>
            <p><span>These cookies are used to make advertising messages more relevant to you. They perform functions like
                preventing the same ad from continuously reappearing, ensuring that ads are properly displayed
                for
                advertisers, selecting advertisements that are based on your interests and measuring the number
                of ads
                displayed and their performance, such as how many people clicked on a given ad.</span></p>
            <p><i>Social Networking Cookies</i></p>
            <p><span>These cookies are used to enable you to share pages and content that you find interesting on our
                website
                through third-party social networking and other websites. These cookies may also be used for
                advertising
                purposes too.</span></p>
            <h3>How can you control cookies?</h3>
            <p><span>The essential cookies are necessary for the website to function and cannot be switched off. They are usually
                only set in response to actions made by you why amount to a request for services, such as setting your language preference.
                You can set your browser to block it but some parts of the site will not then work. We would not use advertising cookies and
                analytics cookies unless you allow us to use them. Please note that changes you make to your
                cookie preferences may make browsing our website a less satisfying experience. In some cases,
                you may even find yourself unable to use all or part of our site.</span></p>
            <p><em><span>Browser and devices controls</span></em></p>
            <p><span>Some web browsers provide settings that allow you to control or reject cookies or to alert you when a
                cookie is placed on your computer. The procedure for managing cookies is slightly different for
                each
                internet browser. You can check the specific steps in your particular browser help menu.</span>
            </p>
            <p><span>You also may be able to reset device identifiers by activating the appropriate setting on your mobile
                device. The procedure for managing device identifiers is slightly different for each device. You
                can check
                the specific steps in the help or settings menu of your particular device.</span></p>
            <h3>Google™ Cookie </h3>
            <p><em><span>Stuff Google Wants to Make Sure You Know about Google’s Data Collection Technology</span></em></p>
            <p><em><span>Google Analytics</span></em></p>
            <p><span>We use Google Analytics, which is a Google service that uses cookies and other data collection
                technologies
                to collect information about your use of the website and services in order to report website
                trends.</span>
            </p>
            <p><span>You can opt out of Google Analytics by visiting </span><a
                href="http://www.google.com/settings/ads"><span>www.google.com/settings/ads</span></a><span>，or
                    by
                    downloading the Google Analytics opt-out browser add-on at </span><a
                        href="https://tools.google.com/dlpage/gaoptout"><span>https://tools.google.com/dlpage/gaoptout</span></a>.
            </p>
            <h3>How to contact us?</h3>
            <p><span>If you have questions about this Cookie Policy, please reach us on <a herf="mailto:support@heymandi.com">support@heymandi.com</a> </span></p>
        </div>
    )
}

export default cookie_en_us