import React, { Component } from "react";
import Header from "../components/Header";
import FeatCard from "../components/FeatCard";
// import NFTCard from "../components/NFTCard";
import FAQ from "../components/FAQ"
import ComingSoon from "./ComingSoon";
import Terms from "./Terms"
import CookiesPolicy from "./CookiesPolicy"
import { Container, Image } from "react-bootstrap";
import { Helmet } from "react-helmet";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { withTranslation, Trans } from 'react-i18next';
import "../css/landing.css";
class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imagePath: "https://dteklg4bowqr.cloudfront.net/nft/website/",
      shouldUseCookies: Cookies.get("cookies_consent"),
    };
  }

  cookiesHelmet = () => {
    return this.state.shouldUseCookies ? (<Helmet>
      {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
      <script async src="https://www.googletagmanager.com/gtag/js?id=UA-77435421-5"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag() { dataLayer.push(arguments); }
          gtag('js', new Date());

          gtag('config', 'UA-77435421-5');
        `}
      </script>
    </Helmet>) : <></>
  }

  onClickCookies = () => {
    document.getElementById('main-container').classList.add('d-none')
    document.getElementById('cookies-container').classList.remove('d-none')
  }

  render() {
    let { imagePath } = this.state
    let { t } = this.props

    return (
      <Container fluid className="g-0">
        <Helmet >
          <title>{t('site-name')}</title>
          <meta name="title" content={t('site-name')} />
          <meta name="description" content={t('description')} />

          <meta property="og:url" content="https://nft.heymandi.com" />
          <meta property="og:site_name" content={t('site-name')} />
          <meta property="og:title" content={t('site-name')} />
          <meta property="og:description" content={t('description')} />
          <meta property="og:image" content={`${this.state.imagePath}assets/images/logo-bg-green.webp`} />
          <meta property="og:type" content="website" />
          <meta property="og:locale" content={this.props.i18n.language} />

          <meta property="twitter:title" content={t('site-name')} />
          <meta property="twitter:description" content={t('description')} />
          <meta property="twitter:image" content={`${this.props.rootUrl}assets/images/logo-bg-green.webp`} />
          <meta property="twitter:url" content="https://nft.heymandi.com" />
          <meta property="twitter:card" content="summary" />
        </Helmet>
        <ComingSoon imagePath={imagePath} />
        <CookiesPolicy imagePath={imagePath} />
        <Terms imagePath={imagePath} />
        <div id="main-container">
          <div id="landing-container">
            <Header imagePath={imagePath} />
            <div className="position-relative">
              <Image alt="" src={`${imagePath}img_top.webp`} id="landing-img-1" fluid="true"></Image>
            </div>
            <div id="landing-body">
              <div id="landing-title">
                {t('header')}
              </div>
              <div id="landing-content">
                {<Trans
                  i18nKey="content"
                  components={{ s: <span className="fw-900" /> }}
                />}
              </div>
            </div>
            <FeatCard imagePath={imagePath} />
          </div>
          {/* <NFTCard imagePath={imagePath} /> */}
          <FAQ imagePath={imagePath} />
        </div>
        <CookieConsent
          location="bottom"
          buttonText={t('accept')}
          cookieName="cookies_consent"
          style={{ background: "#2B373B", color: "#FAFAFA", padding: "0 3.5vw" }}
          buttonStyle={{
            color: "#4e503b", fontSize: "14px", borderRadius: "23px", height: "46px", width: "100px"
          }}
          onAccept={() => { this.setState({ shouldUseCookies: true }); }}
          expires={7}
        >
          <div id="cookie-consent">
            {t('cookies-consent-1')}<span onClick={() => this.onClickCookies()}>{t('cookies-consent-2')}</span>
          </div>
        </CookieConsent >
      </Container>
    );
  }
}

export default withTranslation()(Landing);
