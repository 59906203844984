const cookie_zh_cn = () => {
    return (
        <div key="cookies-policy-zh-cn">
            <h1>Cookie 政策</h1>
            <h3>简介</h3>
            <p><span>Heymandi 致力保护你的隐私。我们的目标是为你提供值得信赖、领先业界的产品和服务，让你只需专心建立更多的人际关系。我们对隐私的做法是提供你清楚的资讯，让你了解我们处理资料的实务。 </span></p>
            <p><span>本 Cookie 政策解释什么是 Cookie、当你造访我们的网站时，哪种 Cookie 会放在你的装置上，以及我们使用 Cookie 的方式。 </span></p>
            <p><span>本 Cookie 政策不会涉及一般我们处理你个人资讯的方式。要了解我们如何处理你的个人资讯，请在</span><a
                href="https://www.heymandi.com/terms/privacy"><span>这里</span></a>参阅我们的隐私政策<span>。 </span></p>
            <h3>什么是 Cookie？ </h3>
            <p><span>Cookie 是向网页浏览器或装置记忆体发送，或从之存取的小文字档。 Cookie 通常包含产生 Cookie 的网域（网际网路位置）名称、Cookie
                的「存留期」（即过期的时间），以及随机产生的特殊数字或类似识别码。 Cookie 还可能包含有关你设备的资讯（例如使用者设定、浏览历程记录，以及使用我们的服务时采取的行动）。 </span></p>
            <h3>有不同类型的 Cookie 吗？ </h3>
            <p><em><span>第一方和第三方厂商 Cookie</span></em></p>
            <p><span>有第一方厂商和第三方厂商 Cookie。第一方厂商 Cookie 会由我们直接放在你的装置上。例如，我们使用第一方厂商 Cookie 让我们的网站适应你浏览器的语言喜好设定，并更加了解你对我们网站的使用。第三方厂商
                Cookie 是由我们的合作伙伴和服务供应商放在你的装置上。例如，我们使用第三方厂商 Cookie 来测量我们网站上的使用者人数，或者让你可跨社群媒体平台与他人分享内容。 </span></p>
            <p><em><span>工作阶段和永续性 Cookie</span></em></p>
            <p><span>还有工作阶段和永续性 Cookie。工作阶段 Cookie 会在浏览器关闭后结束。我们使用工作阶段 Cookie
                的原因很多，包括更了解你在单一浏览器工作阶段中如何使用我们的网站，并协助你更有效使用我们的网站。永续性 Cookie 的寿命较长，而且在浏览器关闭时不会自动移除。这些类型的 Cookie
                旨在协助你快速再次登入我们的网站，以及分析的目的。 </span></p>
            <h3>有其他追踪技术吗，例如 Web 指标？ </h3>
            <p><span>其他技术，例如 Web 指标（也称为像素标记或清晰 gif）、追踪用 URL 或软体开发套件（SDK）等都用于类似目的。 Web
                指标是微小图形档，包含一个特殊识别码，让我们在有人造访我们的服务或开启我们传送的电子邮件时可以辨识。追踪用 URL 是自订产生的连结，可协助我们了解网页的流量来源。 SDK 是包含在 App 中的小块代码，它的功能类似
                Cookie 和 Web 指标。 </span></p>
            <p><span>为了简便起见，本 Cookie 政策将这些技术统称为「Cookie」。 </span></p>
            <h3>我们使用 Cookie 的目的是什么？ </h3>
            <p><span>与多数线上服务供应商一样，我们使用Cookie 来提供、保护和改善我们的服务，包括记住你的喜好、当你造访我们的网站时识别你，并根据你的兴趣量身打造个人化广告。为了实现这些目的，我们还会将 Cookie
                中的资讯与我们持有关于你的其他个人资讯连结起来。 </span></p>
            <p><span>当你造访我们的网站时，以下几种或所有类型的 Cookie 可能会放在你的装置上。 </span></p>
            <p><i>必要网站 Cookie</i></p>
            <p>为了透过我们的网站提供你服务并使用部分网站功能（例如造访安全区域），这些 Cookie 有其绝对必要性。 </p>
            <p><i>分析 Cookie</i></p>
            <p>这些 Cookie 协助我们了解我们的网站如何受到使用、推广活动如何有效进行，并协助我们为你量身打造和改善我们的服务。 </p>
            <p><i>广告 Cookie</i></p>
            <p><span>这些 Cookie 用于让广告资讯与你更为相关。这些 Cookie
                的功能具有诸如防止同一广告持续不断重现、确保广告商的广告正确显示、根据你的兴趣选择广告，并测量显示的广告数量及效能，例如多少人点击了特定广告。 </span></p>
            <h3>你可如何控制 Cookie？ </h3>
            <p><span>必要网站 Cookie 是用作维持网站的正常运作，因此不能关闭。它们通常是只会根据你于网站上的行动来设置，即是你对执行的服务请求，例如设定语言等。
                你可以从你的浏览器设置封锁有关Cookie，但这会让网站的某些部份无法正常运作。只有当你允许我们使用广告Cookie及分析Cookie，我们才会使用它们。请注意，如果你变更 Cookie 喜好设定，可能降低网站浏览品质。某些情况下，你甚至会可能发现你无法使用我们网站的全部或部分内容。 </span></p>
            <p><em><span>浏览器和装置控制项</span></em></p>
            <p><span>部分网页浏览器让你可设定控制或拒绝 Cookie，或者当 Cookie 放在你的电脑上时提醒你。每个网际网路浏览器的 Cookie
                管理程序稍有不同。你可在浏览器说明功能表中了解其特定步骤。 </span></p>
            <p><span>你还可透过启动行动装置上的相应设定，来重新设定装置识别码。每个装置的识别码管理程序稍有不同。你可在装置说明或设定功能表中了解其特定步骤。 </span></p>
            <h3>Google™ Cookie </h3>
            <p><em><span>Google 希望确保你了解 Google 资料收集技术</span></em></p>
            <p><em><span>Google 分析</span></em></p>
            <p><span>我们使用 Google 分析，这项 Google 服务使用 Cookie 和其他资料收集技术，收集有关你使用网站和服务的资讯，以利做成网站趋势报告。 </span></p>
            <p><span>你可透过造访 </span><a
                href="http://www.google.com/settings/ads"><span>www.google.com/settings/ads</span></a><span>，或从 </span><a
                    href="https://tools.google.com/dlpage/gaoptout"><span>https://tools.google.com/dlpage/gaoptout</span></a> 下载
                Google 分析的退出浏览器附加元件，来退出 Google 分析<span>。 </span></p>
            <h3>如何联系我们？ </h3>
            <p><span>如果你对本 Cookie 政策有任何疑问，请电邮至 <a herf="mailto:support@heymandi.com">support@heymandi.com</a> 联系我们。 </span></p>
        </div>)
}

export default cookie_zh_cn