import React, { Component } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import { withTranslation, Trans } from 'react-i18next';
import '../css/faq.css'

let WEBSITE = "website"
let EMAIL = "email"
class FAQ extends Component {

    display = (id) => {
        let question = document.querySelector(`#question-${id}`)
        let icon = question.children[0]
        let answer = document.querySelector(`#answer-${id}`)

        if (answer.classList.contains('d-none')) {
            icon.src = this.props.imagePath + "icn_collapse.webp"
            answer.classList.remove('d-none')
        }
        else{
            icon.src = this.props.imagePath + "icn_expand.webp"
            answer.classList.add('d-none')
        }
    }

    renderFAQ = () => {
        //hard code question to number
        const noOfQuestions = 9
        let faqs = []
        let { t } = this.props

        for (let i = 0; i < noOfQuestions; i++) {
            faqs.push(
                <div key={i+1}>
                    <Row  id={`question-${i+1}`} onClick={()=>this.display(i+1)} >
                        <Col xs={11}>{t(`question-${i+1}`)}</Col>
                        <Col xs={1}><img alt="" src={`${this.props.imagePath}icn_expand.webp`} className='expand'/></Col>
                    </Row>
                    <div id={`answer-${i+1}`} className="d-none answer">
                        {/* {Array.isArray(t(`answer-${i+1}`)) ? this.mapFAQTable(t(`answer-${i+1}`)) : t(`answer-${i+1}`)} */}
                        <Trans 
                            i18nKey={`answer-${i+1}`} 
                            components={{ 
                                website: <AnchorLink type={WEBSITE} t={this.props.t} />,
                                email: <AnchorLink type={EMAIL} t={this.props.t} />,
                            }}
                        />
                    </div>
                    <div className="faq-divider"/>
                </div>
            )
        }

        return (
            <div id="faq-container">
                {faqs}
            </div>
        )
    }

    mapFAQTable = (data) => {
        return (
            <Table responsive="md">
                <thead>
                    <tr>
                        <th className="table-header">Name</th>
                        <th className="table-header">Contract Address</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((e, i) => {
                            return (
                                <tr key={i}>
                                    <th>{e.name}</th>
                                    <th>{e.address}</th>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        )
    }

    onClickTerms = () => {
        document.getElementById('main-container').classList.add('d-none')
        document.getElementById('terms-container').classList.remove('d-none')
      }

    render() {
        let { t } = this.props

        return (
            <div id="faq-bg">
                <div id="faq-title">{t('faq-title')}</div>
                {this.renderFAQ()}
                <div id="tnc">
                    <span onClick={() => this.onClickTerms()}>
                        {t('tnc')}
                    </span>
                </div>
            </div>
        )
    }
}

class AnchorLink extends React.Component {
    render() {
        let { type } = this.props
        let { t } = this.props
        let anchorTag;

        if  (type === EMAIL) {
            anchorTag = <a href="mailto:nft@heymandi.com">nft@heymandi.com</a>
        }
        else if (type === WEBSITE){
            anchorTag =  <a href="https://walletconnect.com/explorer?type=wallet&chains=eip155%3A1">{t('website')}</a>
        }

        return anchorTag
    }
}

export default withTranslation()(FAQ);
